import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { reactI18nextModule } from "react-i18next";

// Nouvelle marque
import frDefault from "./locales/fr/default.json";
import frCdv from "./locales/fr/cdv.json";
import frAv from "./locales/fr/av.json";

i18n
  // load translation using xhr -> see ./locales
  // learn more: https://github.com/i18next/i18next-xhr-backend`
  .use(Backend)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // Nouvelle marque
    resources: {
      fr: {
        default: frDefault,
        cdv: frCdv,
        av: frAv,
      },
    },

    lng: "fr",
    fallbackLng: "fr", // use if detected lng is not available

    // Nouvelle marque
    ns: ["default", "cdv", "av"], // files to load
    defaultNS: "default", // default namespace (needs no prefix on calling t)
    fallbackNS: "default", // fallback, can be a string or an array of namespaces

    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: "./locales/{{lng}}/{{ns}}.json",
    },

    saveMissing: false, // send not translated keys to endpoint
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
      nsMode: "fallback",
    },
  });

export default i18n;
