import "core-js/stable";
import "regenerator-runtime/runtime";
import reportWebVitals from "./reportWebVitals";
import moment from "moment-timezone";
import "moment/locale/fr";
import isEqual from "lodash/isEqual";
import { loadSession, loadSessionOnceFromServer } from "feature";
import * as api from "./utils/api";
import * as storage from "./utils/storage";
import "./utils/i18n";
import "./utils/react-intl-polyfill";

moment.locale("fr");
moment.tz.setDefault("Europe/Paris");

// 1. get the session stored in the sessionStorage & load the ReactApp
let previousSession = storage.local.getItem("session");

if (previousSession !== null) {
  previousSession = JSON.parse(previousSession);
  loadSession(previousSession);
}

// 2 . get the session from the API, update the sessionStorage & re-load the ReactApp
api
  .getSession()
  .then((response) => {
    if (response.status >= 400) {
      throw new Error("getSession: Bad response from server");
    }

    return response.data;
  })
  .then((updatedSession) => {
    storage.local.setItem("session", JSON.stringify(updatedSession));

    loadSessionOnceFromServer(updatedSession);

    // On exclut le dataLayer qui change tout le temps pour éviter de charger toutes les apps 2 fois.
    // → Sachant que le dataLayer n'est utilisé que dans l'app "global-needs-session" qui est appelé par "loadSessionOnceFromServer".
    const updatedSessionWithoutDataLayer = {
      ...updatedSession,
      dataLayer: null,
    };

    const previousSessionWithoutDataLayer = {
      ...previousSession,
      dataLayer: null,
    };

    if (isEqual(updatedSessionWithoutDataLayer, previousSessionWithoutDataLayer) === true) {
      return;
    }

    loadSession(updatedSession);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
