/**
 * Ce helper permet de pouvoir utiliser le localStorage/sessionStorage sur tous les navigateurs.
 *   explications : https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 *   la solution provient de : https://github.com/MichalZalecki/storage-factory (mis à jour le 28 Nov 2018)
 *
 * // COMMENT L'UTILISER:
 * // --------------------
 *
 * import * as storage from "utils/storage";
 *
 * function login(token) {
 *     storage.local.setItem("token", token);
 * }
 *
 * // --------------------
 * // FIN.
 */

// private function
const storageFactory = function (getStorage) {
  let inMemoryStorage = {};

  function isSupported() {
    try {
      const testKey = "__some_random_key_you_are_not_going_to_use__";
      getStorage().setItem(testKey, testKey);
      getStorage().removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  function clear() {
    if (isSupported()) {
      getStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name) {
    if (isSupported()) {
      return getStorage().getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index) {
    if (isSupported()) {
      return getStorage().key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  function removeItem(name) {
    if (isSupported()) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name, value) {
    if (isSupported()) {
      getStorage().setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  }

  function length() {
    if (isSupported()) {
      return getStorage().length;
    } else {
      return Object.keys(inMemoryStorage).length;
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
};

// public functions
export const local = storageFactory(() => localStorage);
export const session = storageFactory(() => sessionStorage);
