/**
 * On charge les composants uniquement lorsqu'on a récupéré la session provenant du server
 */
const loadSessionOnceFromServer = (session) => {
  const global = document.getElementById("react--global-needs-session");
  if (global !== null) {
    import(/* webpackChunkName: "global-needs-session" */ "feature/global-needs-session/index").then((index) => {
      index.default(global, session);
    });
  }

  const checkout = document.getElementById("react--checkout");
  if (checkout !== null) {
    import(/* webpackChunkName: "checkout" */ "feature/checkout/index").then((index) => {
      index.default(checkout, session);
    });
  }

  const travelInformation = document.getElementById("react--travel-information");
  if (travelInformation !== null) {
    import(/* webpackChunkName: "travel-information" */ "feature/travel-information/index").then((index) => {
      index.default(travelInformation, session);
    });
  }

  const userCredits = document.getElementById("react--user-credits");
  if (userCredits !== null) {
    import(/* webpackChunkName: "user-credits" */ "feature/user-credits/index").then((index) => {
      index.default(userCredits, session);
    });
  }

  const review = document.getElementById("react--review");
  if (review !== null) {
    import(/* webpackChunkName: "review" */ "feature/review/index").then((index) => {
      index.default(review, session);
    });
  }
};

/**
 * Tous ces composants vont être rendu 1 ou 2 fois :
 * - 1 fois avec la session provenant du local storage
 * - si la session a changée (connexion / deconnexion / etc.), 1 seconde fois avec la session provenant du serveur (retour de l'API)
 */
const loadSession = (session) => {
  const notifications = document.getElementById("react--notifications");
  if (notifications !== null) {
    import(/* webpackChunkName: "notifications" */ "feature/notifications/index").then((index) => {
      index.default(notifications, session);
    });
  }

  const programmeCards = document.getElementsByClassName("react--programme-card");
  if (programmeCards !== null) {
    Array.from(programmeCards).forEach((programmeCard) => {
      import(/* webpackChunkName: "programme-card" */ "feature/programme-card/index").then((index) => {
        index.default(programmeCard, session);
      });
    });
  }
};

export { loadSession, loadSessionOnceFromServer };
