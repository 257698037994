import axios from "axios";
import isEmpty from "lodash/isEmpty";

export const fetch = (url, options = {}) => axios.get(url, { ...options, withCredentials: true });

export const post = (url, data = null, options = {}) =>
  axios.post(url, data, {
    ...options,
    headers: {
      ...options.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

/**
 * @param url ---- must be an instance of: import URI from 'urijs'; const url = new URI('/api/search-offer');
 * @param filters Object
 */
export const buildQueryFilters = (url, filters = {}) => {
  // eslint-disable-next-line
  Object.keys(filters).map((k) => {
    const filter = filters[k];
    if (!isEmpty(filter)) {
      url.addSearch(k, filter);
    }
  });
};
